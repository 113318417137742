import { createContext, useState } from "react";
import Login from "./Login";
import UploadFileForm from "./UploadFileForm";

export const CredentialsContext = createContext({
  credentials: { username: "", password: "" },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCredentials: (_creds: { username: string; password: string }) => {},
});

export const Admin = () => {
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({
    username: "",
    password: "",
  });
  const isLoggedIn = !!credentials.username && !!credentials.password;
  return (
    <CredentialsContext.Provider
      value={{
        credentials,
        setCredentials,
      }}
    >
      {isLoggedIn ? <UploadFileForm /> : <Login />}
    </CredentialsContext.Provider>
  );
};
