import axios from 'axios'
import { useEffect, useState } from 'react'
import { ErrorDisplayer } from './components/Error'
import { Spinner } from './components/Spinner'

type Document = {
  validityDate: string
  name: string
  id: string
  url: string
}

type Language = 'fr' | 'en'

const getLatestDocument = (language: Language) =>
  axios
    .get<
      unknown,
      {
        data: { document: Document | undefined }
      }
    >(`/api/${language}/documents`)
    .then((result) => {
      return result.data.document
    })

const Document = ({ document }: { document: Document | undefined }) => {
  return document ? (
    <>
      <div
        style={{
          alignSelf: 'center',
          minHeight: 'calc(100vh - 200px)',
          maxWidth: '800px',
          width: '100%',
          marginTop: '15px',
        }}
      >
        <h1 style={{ marginBottom: '15px', textAlign: 'center', fontSize: '20px' }}>
          Version {new Date(document.validityDate).toLocaleDateString()}
        </h1>
        <embed src={document.url} type="application/pdf" width="100%" height="100%" />
      </div>
    </>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>No document to display</div>
  )
}

export const ListDocuments = ({ language }: { language: Language }) => {
  const [document, setDocument] = useState<Document | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    getLatestDocument(language)
      .then(setDocument)
      .catch(() => {
        setError('An error has occured. Please contact HiPay support.')
      })
      .finally(() => setLoading(false))
  }, [language])

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ErrorDisplayer error={error} />
      <Spinner isLoading={loading} />

      {!loading && <Document document={document} />}
    </div>
  )
}
