import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Admin } from './Admin.tsx'
import './index.css'
import { ListDocuments } from './ListDocuments.tsx'
import './reset.css'

const router = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to="/politique_d_acceptation" replace />,
  },
  {
    path: '/politique_d_acceptation',
    element: <ListDocuments language="fr" />,
  },
  {
    path: '/acceptance_policy',
    element: <ListDocuments language="en" />,
  },
  {
    path: '/admin',
    element: <Admin />,
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
