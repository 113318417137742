import axios from 'axios'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CredentialsContext } from './Admin'
import { ErrorDisplayer } from './components/Error'
import { Spinner } from './components/Spinner'

export type Language = 'fr' | 'en'

const getSignedUrl = ({
  language,
  validityDate,
  username,
  password,
}: {
  language: Language
  validityDate: string
  username: string
  password: string
}) =>
  axios
    .get<unknown, { data: { url: string } }>(`/api/uploadable-url`, {
      params: {
        language,
        validityDate,
      },
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    })
    .then((result) => {
      return result.data.url
    })

const UploadFileForm = () => {
  const { register, handleSubmit, reset } = useForm<{
    files: File[]
    validityDate: string
    language: Language
  }>()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const { credentials } = useContext(CredentialsContext)

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true)
      setError('')

      const uploadableUrl = await getSignedUrl({
        language: data.language,
        validityDate: new Date(data.validityDate).toISOString(),
        ...credentials,
      })

      const formData = new FormData()
      formData.append('file', data.files[0])

      await axios.put(uploadableUrl, data.files[0], {
        headers: {
          'x-goog-meta-validitydate': new Date(data.validityDate).toISOString(),
          'Content-Type': 'application/pdf',
        },
      })

      reset()
      setSuccess('Votre document a bien été envoyé')
    } catch (error) {
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  })

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <ErrorDisplayer error={error} />

      {success && (
        <div
          style={{
            marginTop: '35px',
            marginBottom: '35px',
            padding: '15px',
            alignSelf: 'center',
            border: '2px solid green',
            borderRadius: '10px',
            color: 'green',
          }}
        >
          {success}
        </div>
      )}

      <div
        style={{
          border: '1px solid #646cff',
          borderRadius: '10px',
          boxShadow: '2px 4px 13px grey',
          padding: '15px',
        }}
      >
        <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Ajouter un nouveau fichier</h3>

        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          onSubmit={onSubmit}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label>Date de validité</label>
            <input type="date" {...register('validityDate', { required: true })} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label>Langue</label>
            <select {...register('language', { required: true })}>
              <option value="fr">fr</option>
              <option value="en">en</option>
            </select>
          </div>
          <input style={{ padding: '5px' }} type="file" {...register('files', { required: true })} />
          <button type="submit">{loading ? <Spinner isLoading={loading} /> : 'Envoyer'}</button>
        </form>
      </div>
    </div>
  )
}

export default UploadFileForm
