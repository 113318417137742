import axios from 'axios'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CredentialsContext } from './Admin'

type LoginData = {
  username: string
  password: string
}

const login = async ({ username, password }: { username: string; password: string }) =>
  axios
    .post<unknown, { data: { success: boolean } }>(`/api/login`, {
      username,
      password,
    })
    .then((result) => result.data.success == true)

const Login = () => {
  const { register, handleSubmit } = useForm<LoginData>({
    mode: 'onSubmit',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { setCredentials } = useContext(CredentialsContext)

  const onSubmit = handleSubmit(({ username, password }) => {
    setError('')
    setLoading(true)
    login({ username, password })
      .then((success) => {
        if (success) {
          setCredentials({ username, password })
        } else {
          setError('Username or password is incorrect')
        }
      })
      .catch(() => setError('An error has occured. Please contact your support.'))
      .finally(() => setLoading(false))
  })

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {error && (
        <div
          style={{
            marginBottom: '35px',
            padding: '15px',
            alignSelf: 'center',
            border: '2px solid red',
            borderRadius: '10px',
            color: 'red',
          }}
        >
          {error}
        </div>
      )}
      <div
        style={{
          border: '1px solid #646cff',
          borderRadius: '10px',
          boxShadow: '2px 4px 13px grey',
          padding: '15px',
        }}
      >
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          onSubmit={onSubmit}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label>Username</label>
            <input
              {...register('username', {
                required: true,
              })}
              type="text"
              placeholder="Username"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label>Password</label>
            <input
              {...register('password', {
                required: true,
              })}
              type="password"
              placeholder="Password"
            />
          </div>
          <button type="submit">
            {loading ? (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            ) : (
              'Login'
            )}
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
