export const ErrorDisplayer = ({ error }: { error: string | undefined }) => {
  return error ? (
    <div
      style={{
        marginTop: '35px',
        padding: '15px',
        alignSelf: 'center',
        border: '2px solid red',
        borderRadius: '10px',
        color: 'red',
      }}
    >
      {error}
    </div>
  ) : (
    <></>
  )
}
